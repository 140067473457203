import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Link from "../components/link"
import Title from "../components/title"

const VerbTag = styled.span`
  text-transform: uppercase;
  margin-right: 0.5rem;
  padding: 0.25rem;
  flex-grow: 0;
  min-width: 64px;
`
const PathTag = styled.div`
  flex-grow: 10;
`

const referenceQuery = graphql`
  {
    pathsByTag: allOasPath {
      group(field: mainTag) {
        fieldValue
        nodes {
          slug
          verb
          name
        }
      }
    }
  }
`

const TwoItems = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`

const Container = styled.div`
  margin-bottom: 1rem;
`

const ReferenceIndex = () => {
  const data = useStaticQuery(referenceQuery)
  const { pathsByTag } = data

  return (
    <Layout>
      {pathsByTag.group.map(({ fieldValue, nodes }) => (
        <div key={fieldValue}>
          <Title>{fieldValue}</Title>
          <Container>
            {nodes.map(node => (
              <Link to={node.slug} key={node.slug} display={"block"}>
                <TwoItems>
                  <VerbTag>{node.verb}</VerbTag>
                  <PathTag>{node.name}</PathTag>
                </TwoItems>
              </Link>
            ))}
          </Container>
        </div>
      ))}
    </Layout>
  )
}

export default ReferenceIndex
